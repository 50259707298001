<template>
  <div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item">
        <a href="/admin/albums/lists">Quản lý album </a>
      </li>

      <li class="breadcrumb-item">Thêm mới album</li>
    </ol>

    <div v-if="is_load">
      <SkeletonTheme>
        <Skeleton height="100px" v-for="k in 10" :key="k"></Skeleton>
      </SkeletonTheme>
    </div>

    <form method="post" @submit.prevent="mySubmit" id="sendform" v-else>
      <input type="hidden" :value="from.id" name="id" />
      <div class="container">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#home"
              >Tiếng Việt
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#menu1">English </a>
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <div id="home" class="container tab-pane active">
            <br />
            <div class="">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <strong>Thông tin </strong>
                    <small>Form</small>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="name"
                            >Tên<span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            name="name"
                            class="form-control"
                            v-model="from.name"
                          />
                          <p class="text-danger">{{ error.name }}</p>
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="picturefile"
                            >Hình ảnh đại diện<span class="text-danger"
                              >*</span
                            ></label
                          >
                          <input
                            type="file"
                            accept="image/*"
                            name="picturefile"
                            class="form-control main_picture"
                            @change="onPicturefile"
                          />

                          <div class="view_picture_one" v-if="img_one != ''">
                            <a :href="img_one"  rel="gallery" class="fancybox">
                                <img
                                  style="height: 150px"
                                  :src="img_one"


                                  
                                  :alt="from.picture"
                                  class="img-thumbnail "

                                
                                />
                              </a>
                          </div>
                          <p class="text-danger">{{ error.picturefile }}</p>
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="multipicturefiles"
                            >Danh sách Hình ảnh<span class="text-danger"
                              >*</span
                            ></label
                          >
                          <input
                            type="file"
                            class="form-control main_picture"
                            name="multipicturefiles"
                            multiple
                            accept="image/*"
                            @change="onMutilPicturefile"
                          />
                          <div
                            class="view_picture_mutil"
                            v-if="img_mutil.length > 0"
                          >
                            <div   v-for="(el,k) in img_mutil"
                              :key="k" >
                              <div class="border m-2 p-1" style="float: left;">
                                  <input type="button" @click="removepic(k)" class="btn btn-sm btn-danger" name="a" value="Xoá" >
                                  <a  :href="el" rel="gallery" class="fancybox  ">
                                     <img
                                    
                                      :src="el"
                                      :alt="el"
                                      style="height: 150px"

                                      class="mr-3 mt-3 img-thumbnail"
                                    />
                                  </a>
                              </div>

                          </div>
                          </div>
                          <p class="text-danger">
                            {{ error.multipicturefiles }}
                          </p>
                        </div>
                      </div>

                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="content"> Nội dung </label>
                          <ckeditor
                            :config="editorConfig"
                            v-model="content"
                             @namespaceloaded="onNamespaceLoaded"
                          ></ckeditor>
                        </div>
                      </div>

                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="name">Trạng thái </label>
                          <select
                            class="form-control col-sm-3"
                            name="status"
                            v-model="from.status"
                          >
                            <option value="1">Hiện</option>
                            <option value="2">Ẩn</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="menu1" class="container tab-pane fade">
            <br />
            <div class="">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <strong>Album </strong>
                    <small>Form</small>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="name_EN"
                            >Name <span class="text-danger">*</span></label
                          >
                          <input
                            type="text"
                            name="name_EN"
                            class="form-control"
                            v-model="from.name_EN"
                          />
                          <p class="text-danger">{{ error.name_EN }}</p>
                        </div>
                      </div>
                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="content_EN"> Content </label>
                          <ckeditor
                            :config="editorConfig_EN"
                            v-model="content_EN"
                             @namespaceloaded="onNamespaceLoaded"
                          ></ckeditor>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card-footer"
            style="width: 90%; position: fixed; bottom: 0"
          >
            <button
              type="submit"
              class="btn btn-sm btn-primary"
              v-if="!is_load"
            >
              <i class="fa fa-save"></i> Save
            </button>
            <h5 v-else>Loading...</h5>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import axios from "axios";
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";



export default {
  data() {
    return {
      from: {},
      id: this.$route.params.id,
      is_loading: false,
      content: "",
      content_EN: "",
      img_one: "",
      img_mutil: [],
      is_load: false,
      error: {
        name: "",
        picturefile: "",
        multipicturefiles: "",
        name_EN: "",
      },
      myscript:[],
      extendLoaded:false,
      my_url: window.my_url,

      status_img_one: false,
      status_img_multil: false,

      editorConfig: window.editorConfig,
      editorConfig_EN: window.editorConfig_EN,
    };
  },
  components: {
    Skeleton,
    SkeletonTheme,
  },
  created(){
    
   
          
  },
  mounted() {
    this.is_load = true;
    let p1=axios
      .get(window.my_api + "api/album/get-album-by-id?albumId=" + this.id,
            {
              headers: window.my_header
            }
      )
      .then((res) => {
        if (res.status == 200) {
          this.from = res.data.data;
          if (
            res.data.data.picture &&
            res.data.data.picture != null &&
            res.data.data.picture != ""
          ) {
            this.img_one = this.my_url + "" + res.data.data.picture;

         


          }
          if (
            res.data.data.multiPicture &&
            res.data.data.multiPicture != null &&
            res.data.data.multiPicture != ""
          ) {
            let arr = this.img_mutil;
            res.data.data.multiPicture.split(",").forEach((el) => {
              arr.push(this.my_url + "" + el);
            });
            this.img_mutil = arr;
          }
          this.content = res.data.data.content;
          this.content_EN = res.data.data.content_EN;
          
        } else {
          alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
        }
      });
     
      Promise.all([p1]).then(()=>{

              //  let recaptchaScript1 = document.createElement("script");
             
              // recaptchaScript1.setAttribute("src", "/layout/admin/jquery.fancybox/fancybox/jquery.fancybox-1.3.4.js");
              //  document.head.appendChild(recaptchaScript1);

              // let recaptchaScript3 = document.createElement("script");
             
              // recaptchaScript3.setAttribute("src", "/layout/admin/js/main.js");
              //  document.head.appendChild(recaptchaScript3);

                this.myscript=[ {
                                   
                                    src:"/layout/admin/jquery.fancybox/fancybox/jquery.fancybox-1.3.4.js",
                                    

                                  },
                                  {
                                  
                                    src:"/layout/admin/js/main.js",
                                    body:true
                                  }];
               this.is_load = false;

      });

  },

  methods: {
    removepic(id){
       let multiPicture=[];
       for (var i = this.from.multiPicture.split(",").length - 1; i >= 0; i--) {
         if(i!=id){
            multiPicture.push(this.from.multiPicture.split(",")[i]);
         }
       }
        this.from.multiPicture=multiPicture.join(",");

        this.mySubmit();

           
       
    },
    onNamespaceLoaded( CKEDITOR ) {
        CKEDITOR.plugins.addExternal( 'video', '/layout/ckeditor/video/plugin.js' );
    },
    alertError() {
      let tt = "";
      for (let i = 0; i < Object.values(this.error).length; i++) {
        if (Object.values(this.error)[i]) {
          tt =
            tt +
            "<ul style='text-align:left;'><li>" +
            Object.values(this.error)[i] +
            "</li></ul>";
        }
      }
      return tt;
    },
    mySubmit: function () {
      let myForm = document.getElementById("sendform");
      let formData = new FormData(myForm);
      for (var pair of formData) {
        if (pair[0] == "name") {
          if (pair[1] == "") {
            this.error.name = "Vui lòng nhập tên.";
          } else {
            this.error.name = "";
          }
        }
        if (pair[0] == "name_EN") {
          if (pair[1] == "") {
            this.error.name_EN = "Vui lòng nhập tên - Tiếng Anh.";
          } else {
            this.error.name_EN = "";
          }
        }
        if (pair[0] == "picturefile") {
          if (pair[1].name == "") {
            this.status_img_one = true;
          }
        }
        if (pair[0] == "multipicturefiles") {
          if (pair[1].name == "") {
            this.status_img_multil = true;
          }
        }
      }

      if (this.error.name == "" && this.error.name_EN == "") {
        this.is_load = true;
        formData.append("content", this.content);
        formData.append("content_EN", this.content_EN);
        if (this.status_img_one) {
          formData.delete("picturefile");
          formData.append("picture", this.from.picture);
        }
        if (this.status_img_multil) {
          formData.delete("multipicturefiles");
          
        }
        formData.append("multiPicture", this.from.multiPicture);
        axios
          .post(window.my_api + "api/album/create-or-update-album", formData,
              {
              headers: window.my_header
            }
          )
          .then((res) => {
            if (res.status == 200) {
              

              alert("Cập nhật thành công");
              
              this.$router.go(this.$router.currentRoute);
             document.getElementById("sendform").reset();
             this.is_load = false;
            } else {
              this.is_load = false;
              alert("Thêm không thành công, vui lòng liên hệ admin");
            }
          });
      } else {
        
        console.log(this.alertError());
         alert("Cập nhật không thành công");
      }
    },
    onPicturefile(e) {
      let _this = this;
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        _this.img_one = reader.result;
      };
      reader.readAsDataURL(file);
    },
    onMutilPicturefile(e) {
      let arr = [];
      let _this = this;
      Object.keys(e.target.files).forEach((i) => {
        let file = e.target.files[i];
        var reader = new FileReader();
        reader.onloadend = function () {
          arr.push(reader.result);
          _this.img_mutil = arr;
        };
        reader.readAsDataURL(file);
      });
    },
  },
  metaInfo(){
    return {
        script:this.myscript
    }
  }
};
</script>